import React from "react";
import "./Error.css";

import { LinkContainer } from "react-router-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faHome, faInfoCircle } from "@fortawesome/free-solid-svg-icons";

class Error404 extends React.Component {
    render() {
        return (
            <div className="bgimg">
                <div className="bg-layout">
                    <div className="text-center error-banner">
                        <h1 className="title-404">404</h1>

                        <p className="sub-title-404">
                            <FontAwesomeIcon
                                icon={faInfoCircle}
                                className="info-icon"
                                fixedWidth
                            />
                             Oops! The page you requested was not found.</p>
                        <LinkContainer to="/">
                            <a className="home-icon-link" href="javascript:void(0);">
                                <FontAwesomeIcon
                                    icon={faHome}
                                    className="home-icon"
                                    fixedWidth
                                />
                            </a>
                        </LinkContainer>

                    </div>
                </div>
            </div>
        );
    }
}

export default Error404;