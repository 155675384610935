import firebase from "firebase";

// Your web app's Firebase configuration
const config = {
  apiKey: "AIzaSyAjxibYRJ0-dpd2B7EYQT2m9ZzDyMH6BiA",
  authDomain: "iskl-app.firebaseapp.com",
  projectId: "iskl-app",
  storageBucket: "iskl-app.appspot.com",
  messagingSenderId: "560910368936",
  appId: "1:560910368936:web:f758aae7b49c961340029d",
};
// Initialize Firebase
firebase.initializeApp(config);

export default firebase;
