import React from "react";
import {Switch, Route} from "react-router-dom";

import Home from "./components/LandingPage/LandingPage";
import { NotFound } from "./components/Errors";

function App() {
  return (
    <div>
      <div className="">
        {/* A <Switch> looks through its children <Route>s and
          renders the first one that matches the current URL. */}
        <Switch>
          <Route exact path="/" component={Home} />
          <Route exact path="*" component={NotFound} />
        </Switch>
      </div>
    </div>
  );
}

export default App;